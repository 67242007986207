@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url("https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap"); */
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

@layer base {
  body {
    @apply font-[Montserrat] scrollbar-thumb-[#233554] 
    scrollbar-track-transparent scrollbar-thin scrollbar-thumb-rounded-full;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.blur-backdrop-filter {
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
}

.text-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#64ffda, #64ffda);
  background-size: 0 1px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.2s ease-in-out;
}

.text-underline:hover {
  background-size: 100% 1px;
  background-position: 0 100%;
}

.before-arrow:before {
  content: '▹';
  position: absolute;
  margin-left: -24px;
  color: #64ffda;
}

@media screen and (min-width: 0px) and (max-width: 768px) {
  .background {
    background-color: #112240;
    border-radius: 0.375rem;
    padding: 8px 32px 8px 32px;
  }
  .wrap {
    flex-wrap: wrap;
  }
  .margin {
    margin-right: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
